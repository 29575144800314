var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"ca49572d0cadcb1e4f72d6600240934f0ba90bfa"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

Sentry.init({
  dsn: "https://ccf3bbfc8f9f62e14aa65033b6dd8954@o4508013286391808.ingest.us.sentry.io/4508377986564096",
  sampleRate: 0.2,
  tracesSampleRate: 0,
  debug: false,
  replaysOnErrorSampleRate: 0.0,
  replaysSessionSampleRate: 0.0,
  integrations: [],
});
